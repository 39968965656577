import { render, staticRenderFns } from "./hot.vue?vue&type=template&id=4fc2e73a&scoped=true"
import script from "./hot.vue?vue&type=script&lang=ts"
export * from "./hot.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc2e73a",
  null
  
)

export default component.exports