
/**
 * @name: 会员管理-会员信息管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 会员管理-会员信息管理
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  memberPageApi,
  memberExportApi,
  memberCommunityPageApi,
  memberCommunityExportApi,
  memberContributePageApi,
  memberContributeExportApi,
  memberBalancePageApi,
  memberBalanceExportApi
} from '@/apis/member/list'
import config from "@/config"
import {
  IMember,
  IMemberParams
} from '@/apis/member/list/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class memberList extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IMember[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IMemberParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IMember> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: '会员',
        prop: 'member',
        search: true,
        hide: true,
        placeholder: 'ID/昵称/手机号'
      },
      {
        label: '会员ID',
        prop: 'id',
        width: 120,
        align: 'left'
      },
      {
        label: '昵称',
        prop: 'nickName',
        align: 'center',
        overHidden: true,
        width: 150
      },
      {
        label: '头像',
        prop: 'avatarUrl',
        align: 'center',
        type: 'image',
        width: 120
      },
      {
        label: '手机号',
        prop: 'phone',
        align: 'center',
        width: 120,
        slot: true
      },
      {
        label: '性别',
        prop: 'sex',
        align: 'center',
        type: 'select',
        search: true,
        width: 100,
        // @ts-ignore
        default: '--',
        dicData: [
          {
            label: '男',
            value: '男'
          },
          {
            label: '女',
            value: '女'
          }
        ]
      },
      {
        label: '生日',
        prop: 'birthday',
        align: 'center',
        width: 100
      },
      {
        label: '会员等级',
        prop: 'gradeId',
        search: true,
        hide: true,
        type: 'select',
        dicUrl: '/api/admin/user/grade/getList',
        dicFormatter: (res: any) => ({list: res.data, label: 'gradeName', value: 'id'}),
      },
      {
        label: '会员等级',
        prop: 'gradeName',
        align: 'center',
        width: 100,
      },
      {
        label: '推荐人',
        prop: 'recommendedBy',
        align: 'center',
        search: true,
        hide: true,
        placeholder: 'ID/手机号'
      },
      {
        label: '推荐人ID',
        prop: 'pid',
        align: 'center',
        width: 120
      },
      {
        label: '邀请码',
        prop: 'invitationCode',
        align: 'center',
        width: 100
      },
      {
        label: '会员贡献值',
        prop: 'communityIntegral',
        align: 'center',
        slot: true,
        width: 150
      },
      {
        label: '级别贡献值',
        prop: 'gradeIntegral',
        align: 'center',
        slot: true,
        width: 150
      },
      {
        label: '余额（元）',
        prop: 'balancePrice',
        align: 'center',
        slot: true,
        width: 150
      },
      {
        label: '会员状态',
        prop: 'status',
        align: 'center',
        search: true,
        width: 100,
        type: 'select',
        dicData: [
          {
            label: '激活',
            value: 1
          },
          {
            label: '注销',
            value: 2
          }
        ]
      },
      {
        label: '注册时间',
        prop: 'registerTime',
        align: 'center',
        search: true,
        type: 'daterange',
        width: 150
      }
    ]
  }

  communityIntegralLoading = false
  communityIntegralForm: any = {}
  communityIntegralDialog = false
  communityIntegralQuery: any = {
    page: 1,
    limit: 10,
    userId: ''
  }
  communityIntegralTotal = 0
  communityIntegralList: any = []
  communityIntegralOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: '变更类型',
        prop: 'type',
        search: true,
        type: 'select',
        align: 'center',
        hide: true,
        dicData: [
          {
            label: '消费贡献值',
            value: 1
          },
          {
            label: '分享贡献值',
            value: 2
          },
          {
            label: '申报燃烧',
            value: 3
          },
          {
            label: '消费燃烧',
            value: 4
          },
          {
            label: '级别贡献值',
            value: 5
          },
          {
            label: '商家贡献值',
            value: 6
          },
          {
            label: '领袖合伙人贡献值',
            value: 7
          },
          {
            label: '精英合伙人贡献值',
            value: 8
          },
          {
            label: '消费燃烧退还',
            value: 9
          },
          {
            label: '申报燃烧退还',
            value: 10
          }
        ]
      },
      {
        label: '变更类型',
        prop: 'changeType',
        align: 'center'
      },
      {
        label: '关联单号',
        prop: 'orderId',
        search: true,
        align: 'center'
      },
      {
        label: '变更贡献值',
        prop: 'integral',
        align: 'center',
        slot: true
      },
      {
        label: '变更时间',
        prop: 'addTime',
        align: 'center',
        type: 'daterange',
        search: true
      }
    ]
  }


  contributeIntegralLoading = false
  contributeIntegralForm: any = {}
  contributeIntegralDialog = false
  contributeIntegralQuery: any = {
    page: 1,
    limit: 10,
    userId: ''
  }
  contributeIntegralTotal = 0
  contributeIntegralList: any = []
  contributeIntegralOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: '会员等级',
        prop: 'gradeId',
        search: true,
        type: 'select',
        align: 'center',
        dicUrl: '/api/admin/user/grade/getList',
        dicFormatter: (res: any) => ({list: res.data, label: 'gradeName', value: 'id'}),
      },
      {
        label: '当日级别贡献值',
        prop: 'beforeIntegral',
        align: 'center'
      },
      {
        label: '当日变更',
        prop: 'integral',
        align: 'center',
        slot: true
      },
      {
        label: '变更时间',
        prop: 'addTime',
        align: 'center',
        type: 'daterange',
        search: true
      }
    ]
  }



  balanceLoading = false
  balanceForm: any = {}
  balanceDialog = false
  balanceQuery: any = {
    page: 1,
    limit: 10,
    userId: ''
  }
  balanceTotal = 0
  balanceList: any = []
  balanceOption: ICrudOption = {
    menu: false,
    column: [

      {
        label: '变更类型',
        prop: 'type',
        align: 'center',
        type: 'select',
        dicData: [
          {
            label: '个人消费',
            value: 1
          },
          {
            label: '个人消费退还',
            value: 2
          },
          {
            label: '后台充值',
            value: 3
          }
        ]
      },
      {
        label: '关联订单ID',
        prop: 'orderId',
        align: 'center',
        search: true
      },
      {
        label: '变更金额（元）',
        prop: 'price',
        align: 'center',
        slot: true
      },
      {
        label: '变更时间',
        prop: 'addTime',
        align: 'center',
        type: 'daterange',
        search: true
      }
    ]
  }

  getList () {
    this.tableLoading = true

    const query: IMemberParams = deepCopy(this.queryParam)
    if (query.registerTime && query.registerTime.length) {
      query.registerStartTime = query.registerTime[0]
      query.registerEndTime = query.registerTime[1]
    } else {
      query.registerStartTime = ''
      query.registerEndTime = ''
    }
    delete query.registerTime

    memberPageApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }


  export1 () {
    const query: IMemberParams = deepCopy(this.queryParam)
    if (query.registerTime && query.registerTime.length) {
      query.registerStartTime = query.registerTime[0]
      query.registerEndTime = query.registerTime[1]
    } else {
      query.registerStartTime = ''
      query.registerEndTime = ''
    }
    delete query.registerTime
    memberExportApi(query).then(e => {
      exportFile(e, '会员信息.xlsx')
    })
  }

  getCommunityIntegralData () {
    return new Promise(resolve => {
      this.communityIntegralLoading = true

      const query: any = deepCopy(this.communityIntegralQuery)
      if (query.addTime && query.addTime.length) {
        query.addStartTime = query.addTime[0]
        query.addEndTime = query.addTime[1]
      } else {
        query.addStartTime = ''
        query.addEndTime = ''
      }
      delete query.addTime

      memberCommunityPageApi(query).then(e => {
        this.communityIntegralList = e.list
        this.communityIntegralTotal = e.total
        resolve('')
      }).finally(() => {
        this.communityIntegralLoading = false
      })
    })
  }

  getContributeIntegralData () {
    return new Promise(resolve => {
      this.contributeIntegralLoading = true

      const query: any = deepCopy(this.contributeIntegralQuery)
      if (query.addTime && query.addTime.length) {
        query.addStartTime = query.addTime[0]
        query.addEndTime = query.addTime[1]
      } else {
        query.addStartTime = ''
        query.addEndTime = ''
      }
      delete query.addTime

      memberContributePageApi(query).then(e => {
        this.contributeIntegralList = e.list
        this.contributeIntegralTotal = e.total
        resolve('')
      }).finally(() => {
        this.contributeIntegralLoading = false
      })
    })
  }

  getBalanceData () {
    return new Promise(resolve => {
      this.balanceLoading = true

      const query: any = deepCopy(this.balanceQuery)
      if (query.addTime && query.addTime.length) {
        query.addStartTime = query.addTime[0]
        query.addEndTime = query.addTime[1]
      } else {
        query.addStartTime = ''
        query.addEndTime = ''
      }
      delete query.addTime

      memberBalancePageApi(query).then(e => {
        this.balanceList = e.list
        this.balanceTotal = e.total
        resolve('')
      }).finally(() => {
        this.balanceLoading = false
      })
    })
  }

  async openCommunityIntegralDialog (row: IMember) {
    if (!this.$store.getters.hasPermission('logistic:check:community')) {
      return
    }
    this.communityIntegralQuery.page = 1
    this.communityIntegralQuery.limit = 10
    this.communityIntegralQuery.userId = row.id
    this.communityIntegralQuery.orderId = ''
    this.communityIntegralQuery.type = ''
    this.communityIntegralQuery.addTime = []

    await this.getCommunityIntegralData()
    this.communityIntegralDialog = true
  }

  async openContributeIntegralDialog (row: IMember) {
    if (!this.$store.getters.hasPermission('logistic:check:rank')) {
      return
    }
    this.contributeIntegralQuery.page = 1
    this.contributeIntegralQuery.limit = 10
    this.contributeIntegralQuery.userId = row.id
    this.contributeIntegralQuery.addTime = []
    this.contributeIntegralQuery.gradeId = ''
    await this.getContributeIntegralData()
    this.contributeIntegralDialog = true
  }

  async openBalanceDialog (row: IMember) {
    if (!this.$store.getters.hasPermission('logistic:check:balance')) {
      return
    }
    this.balanceQuery.page = 1
    this.balanceQuery.limit = 10
    this.balanceQuery.userId = row.id
    this.balanceQuery.addTime = []
    this.balanceQuery.orderId = ''
    await this.getBalanceData()
    this.balanceDialog = true
  }

  formatCommunituIntegral (row: any) {
    if (parseFloat(row.integral) === 0) {
      return row.integral
    }
    if (row.type === 3 || row.type === 4) {
      return `-${row.integral}`
    }
    return `+${row.integral}`
  }

  formatBalance (row: any) {
    if (row.type === 1) {
      return `-${row.price}`
    }
    return `+${row.price}`
  }

  exportCommunityIntegral () {

    const query: any = deepCopy(this.communityIntegralQuery)

    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    memberCommunityExportApi(query).then(e => {
      exportFile(e, "会员贡献值变更记录.xlsx")
    })
  }

  exportContributeIntegral () {
    const query: any = deepCopy(this.contributeIntegralQuery)

    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    memberContributeExportApi(query).then(e => {
      exportFile(e, "级别贡献值变更记录.xlsx")
    })
  }

  exportBalance () {
    const query: any = deepCopy(this.balanceQuery)

    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    memberBalanceExportApi(query).then(e => {
      exportFile(e, "个人余额变更记录.xlsx")
    })
  }

  created () {
    this.getList()
  }
}
