/**
 * @name: 数据统计-特殊商品业绩统计接口文件
 * @author: mobai
 * @date: 2024-10-16 10:03
 * @description： 数据统计-特殊商品业绩接口文件
 * @update: 2024-10-16 10:03
 */
import { get, postJ } from "@/request";
import { IStatisticsSpecialGood, IStatisticsSpecialGoodParams } from "./types";
import { IPage, IPageRes } from "@/apis/page";

export const productTreeApi = () => get("/admin/special/product/productTree", {})

export const addSpecialGoodApi = (data: {productId: string}[]) => postJ("/admin/special/product/create", data)

export const specialGoodPageApi = (params: IStatisticsSpecialGoodParams) => get<IPageRes<IStatisticsSpecialGood[]>>("/admin/special/product/statistics/query", params)

export const addedProductQueryApi = (page: IPage) => get("/admin/special/product/query", page)

export const manageProductDelApi = (id: string) => get("/admin/special/product/remove/" + id)

export const specialGoodDetailApi = (params: any) => get("/admin/special/product/statistics/query/dtl", params)

export const specialGoodExportApi = (params: IStatisticsSpecialGoodParams) => get("/admin/special/product/statistics/export", params, 'blob')

export const specialGoodDetailExportApi = (params: any) => get("/admin/special/product/statistics/export/dtl", params, 'blob')
