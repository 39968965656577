
/**
 * @name: 商城管理-邀请海报模板管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-邀请海报模板管理
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  posterPageApi,
  posterCreateApi,
  posterModifyApi,
  posterDelApi
} from '@/apis/mall/poster'
import type {
  IPosterParams,
  IPoster
} from '@/apis/mall/poster/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from "@/utils/common";

@Component({})
export default class mallPoster extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IPoster[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IPosterParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IPoster> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    addTitle: '新增邀请海报模板',
    editTitle: '编辑邀请海报模板',
    dialogWidth: '600px',
    column: [
      {
        label: '模板ID',
        prop: 'id',
        width: 180,
        align: 'left',
        addHide: true,
        editHide: true
      },
      {
        label: '模板图片',
        prop: 'pic',
        type: 'image',
        imgSuffix: ';',
        align: 'center',
        addSlot: true,
        editSlot: true,
        rules: [
          {
            required: true,
            message: '请上传模板图片',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '经办人',
        prop: 'realName',
        search: true,
        align: 'center',
        addHide: true,
        editHide: true
      },
      {
        label: '创建时间',
        prop: 'addTime',
        search: true,
        width: 180,
        align: 'center',
        addHide: true,
        editHide: true,
        type: 'daterange'
      },
      {
        label: '状态',
        prop: 'status',
        search: true,
        type: 'select',
        align: 'center',
        editHide: true,
        addHide: true,
        slot: true,
        width: 150,
        dicData: [
          {
            label: '启用',
            value: 1
          },
          {
            label: '禁用',
            value: 2
          }
        ]
      }
    ]
  }

  beforeUpload (file: File) {
    const fileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    const fileSize = file.size / 1024 < 500;

    if (!fileType) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!fileSize) {
      this.$message.error('上传图片大小不能超过 500KB!');
    }
    return fileType && fileSize;
  }

  rowSave (form: IPoster, done: Function, loading: Function) {
    posterCreateApi(form).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IPoster, done: Function, loading: Function) {
    posterModifyApi(form).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  getList () {
    this.tableLoading = true

    const query: IPosterParams = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.startTime = query.addTime[0]
      query.endTime = query.addTime[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.addTime

    posterPageApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  switchStatus (id: string, status: number) {
    posterModifyApi({id, status}).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
      }
    })
  }

  rowDel (row: IPoster) {
    posterDelApi(row.id).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
      }
    })
  }

  created () {
    this.getList()
  }
}
