
/**
 * @name: 数据统计-外链跳转次数统计
 * @author: mobai
 * @date: 2024-10-11 14:56
 * @description： 数据统计-外链跳转次数统计
 * @update: 2024-10-11 14:56
 */
import { Vue, Component, Watch } from "vue-property-decorator"
import type { ICrudOption } from "@/types/m-ui-crud"
import {
  productTreeApi,
  addSpecialGoodApi,
  specialGoodPageApi,
  addedProductQueryApi,
  manageProductDelApi,
  specialGoodDetailApi,
  specialGoodExportApi,
specialGoodDetailExportApi
} from '@/apis/statistics/special-good'
import { deepCopy, exportFile } from '@/utils/common'

@Component({})
export default class statisticsSpecialGood extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: '会员ID',
        prop: "userId",
        align: "left"
      },
      {
        label: '昵称',
        prop: "nickName",
        align: "center"
      },
      {
        label: '用户',
        prop: "userIdOrNameOrPhone",
        hide: true,
        search: true,
        placeholder: "会员ID/昵称/手机号"
      },
      {
        label: '手机号',
        prop: "phone",
        align: "center"
      },
      {
        label: '商品编号',
        prop: "productId",
        align: "center"
      },
      {
        label: '商品名称',
        prop: "productName",
        align: "center"
      },
      {
        label: '商品',
        prop: "productIdOrName",
        hide: true,
        search: true,
        placeholder: "商品编号/商品名称"
      },
      {
        label: '业绩贡献',
        prop: "payQty",
        align: "center",
        slot: true
      },
    ]
  }

  manageProductDialog = false

  saleDetailDialog = false

  addProductDialog = false

  filterText = ''

  options: any = []

  @Watch('filterText')
  filterTextChange (newVal: string) {
    if (this.$refs.tree) {
      // @ts-ignore
      this.$refs.tree.filter(newVal);
    }
  }

  // 管理商品表格加载状态
  manageProductTableLoading: boolean = true;
  // 管理商品表格数据
  manageProductTableData: any = []
  // 管理商品表格总数
  manageProductTableTotal = 0
  // 管理商品查询参数
  manageProductQueryParam: any = {
    page: 1,
    limit: 10
  }
  // 管理商品表单参数
  manageProductModelForm: any = {}
  // 管理商品crud配置信息
  manageProductCrudOption: ICrudOption = {
    searchBox: false,
    menuWidth: 100,
    column: [
      {
        label: "商品编号",
        prop: "productId",
        align: "center",
        width: 180
      },
      {
        label: "商品名称",
        prop: "productName",
        align: "center",
        overHidden: true
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: "center",
        overHidden: true
      },
      {
        label: "成本价(元)",
        prop: "costPrice",
        align: "center"
      },
      {
        label: "零售价(元)",
        prop: "retailPrice",
        align: "center"
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        type: 'select',
        dicData: [
          {
            label: '上架',
            value: 1
          },
          {
            label: '下架',
            value: 2
          }
        ]
      },
      {
        label: "最近一次添加时间",
        prop: "addTime",
        align: "center",
        width: 180
      }
    ]
  }



  // 业绩详情表格加载状态
  saleDetailTableLoading: boolean = true;
  // 业绩详情表格数据
  saleDetailTableData: any = []
  // 业绩详情表格总数
  saleDetailTableTotal = 0
  // 业绩详情查询参数
  saleDetailQueryParam: any = {
    page: 1,
    limit: 10,
    userId: '',
    productId: ''
  }
  // 业绩详情表单参数
  saleDetailModelForm: any = {}
  // 业绩详情crud配置信息
  saleDetailCrudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "sku编号",
        prop: "productSkuId",
        align: "left",
        search: true,
        width: 180,
      },
      {
        label: "规格属性",
        prop: "skuName",
        align: "center",
        overHidden: true,
        width: 150
      },
      {
        label: "成本价(元)",
        prop: "costPrice",
        align: "center",
        width: 120
      },
      {
        label: "零售价(元)",
        prop: "retailPrice",
        align: "center",
        width: 120
      },
      {
        label: "数量",
        prop: "payQty",
        align: "center",
        width: 100
      },
      {
        label: "商品金额(元)",
        prop: "productPrice",
        align: "center",
        width: 120
      },
      {
        label: "商品利润(元)",
        prop: "beforeProfitPrice",
        align: "center",
        width: 120
      },
      {
        label: "关联订单ID",
        prop: "orderId",
        align: "center",
        search: true,
        overHidden: true,
        width: 180
      },
      {
        label: "团队会员ID",
        prop: "orderUserId",
        align: "center",
        search: true,
        width: 180
      },
      {
        label: "支付方式",
        prop: "payWay",
        align: "center",
        search: true,
        type: 'select',
        width: 120,
        dicData: [
          {
            "label": "微信",
            "value": 1
          },
          {
            "label": "支付宝",
            "value": 2
          },
          {
            "label": "贡献值",
            "value": 3
          },
          {
            "label": "余额",
            "value": 4
          },
          {
            "label": "银联支付",
            "value": 5
          }
        ]
      },
      {
        label: "是否计入",
        prop: "isStatistics",
        align: "center",
        search: true,
        type: "select",
        width: 100,
        dicData: [
          {
            label: "是",
            value: 1
          },
          {
            label: "否",
            value: 2
          },
        ]
      },
      {
        label: "完成时间",
        prop: "finishTime",
        align: "center",
        search: true,
        type: "daterange",
        width: 150
      }
    ]
  }

  getList () {
    this.tableLoading = true
    specialGoodPageApi(this.queryParam).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  getSaleDetailList () {
    return new Promise(resolve => {
      const query: any = deepCopy(this.saleDetailQueryParam)
      if (query.finishTime && query.finishTime.length == 2) {
        query.finishStartTime = query.finishTime[0]
        query.finishEndTime = query.finishTime[1]
      } else {
        query.finishStartTime = ''
        query.finishEndTime = ''
      }
      delete query.finishTime

      this.saleDetailTableLoading = true

      specialGoodDetailApi(query).then((e: any) => {
        this.saleDetailTableLoading = false
        this.saleDetailTableData = e.list
        this.saleDetailTableTotal = e.total
        resolve(e)
      })
    })
  }

  async openSaleDetailDialog (row: any) {

    this.saleDetailQueryParam.page = 1
    this.saleDetailQueryParam.limit = 10
    this.saleDetailQueryParam.userId = row.userId
    this.saleDetailQueryParam.productId = row.productId

    this.saleDetailQueryParam.productSkuId = ''
    this.saleDetailQueryParam.orderId = ''
    this.saleDetailQueryParam.orderUserId = ''
    this.saleDetailQueryParam.payWay = ''
    this.saleDetailQueryParam.isStatistics = ''
    this.saleDetailQueryParam.finishTime = []

    await this.getSaleDetailList()

    this.saleDetailDialog = true
  }

  exportExcel () {
    specialGoodExportApi(this.queryParam).then(e => {
      exportFile(e, '特殊商品业绩统计.xlsx')
    })
  }

  exportDetailExcel () {

  }

  exportSaleDetailExcel () {
    const query: any = deepCopy(this.saleDetailQueryParam)
    if (query.finishTime && query.finishTime.length == 2) {
      query.finishStartTime = query.finishTime[0]
      query.finishEndTime = query.finishTime[1]
    } else {
      query.finishStartTime = ''
      query.finishEndTime = ''
    }
    delete query.finishTime
    specialGoodDetailExportApi(query).then(e => {
      exportFile(e, '业绩贡献明细.xlsx')
    })
  }

  removeManageProduct (id: string) {
    this.$confirm('是否确认移除？', '提示', {
      type: 'warning',
    }).then(() => {
      manageProductDelApi(id).then(e => {
        if (e) {
          this.$message.success('操作成功!')
          this.getManageProductList()
        }
      })
    }).catch(() => {
    })
  }

  async openAdd() {
    await this.getProductList()
    this.addProductDialog = true
  }

  async openManageProduct() {
    this.manageProductQueryParam.page = 1
    this.manageProductQueryParam.limit = 10

    await this.getManageProductList()

    this.manageProductDialog = true
  }

  getManageProductList () {
    return new Promise(resolve => {
    this.manageProductTableLoading = true
      addedProductQueryApi(this.manageProductQueryParam).then(e => {
        if (e) {
          this.manageProductTableData = e.list
          this.manageProductTableTotal = e.total
          this.manageProductTableLoading = false
          resolve(e)
        }
      })
    })
  }

  getProductList () {
    return new Promise(resolve => {
      productTreeApi().then(e => {
        this.options = e;
        resolve(e)
      })
    })
  }

  filterNode (value: string, data: any) {
    if (!value) return true;
    return data.name.indexOf(value) !== -1;
  }

  addEnter (done: Function, loading: Function) {
    // @ts-ignore
    const arr: any = this.$refs.tree.getCheckedNodes()
    if (!arr || !arr.length) {
      this.$message.error('请选择数据')
      loading()
      return
    }
    const productArr: string[] = arr.filter((item: any) => item.productId).map((item: any) => item.productId)
    if (!productArr || !productArr.length) {
      this.$message.error('请选择商品数据')
      loading()
      return
    }
    addSpecialGoodApi(productArr.map(item => ({productId: item}))).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getManageProductList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  getSpDataValues (str: string) {
    let json: {key: string, value: string}[];
    try {
      json = JSON.parse(str || '[]')
    } catch (e) {
      json = []
    }
    return json.map(item => item.value).join(';')
  }

  addClose () {
    this.options = []
    this.filterText = ''
  }

  created () {
    this.getList()
  }
}
