import { render, staticRenderFns } from "./third-product.vue?vue&type=template&id=3d24f861&scoped=true"
import script from "./third-product.vue?vue&type=script&lang=ts"
export * from "./third-product.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d24f861",
  null
  
)

export default component.exports