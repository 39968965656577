
/**
 * @name: 商城管理-超值特惠
 * @author: lili
 * @date: 2024-09-23 11:37
 * @description： 商城管理-超值特惠
 * @update: 2024-09-23 11:37
 */
import { Vue, Component, Watch } from "vue-property-decorator"
import { sortPageApi } from '@/apis/product/sort'
import type { ICrudOption } from "@/types/m-ui-crud"
import { IProductSort } from "@/apis/product/sort/types";
import {IDiscountsProduct, IDiscountsProductParams} from "@/apis/mall/discountsProduct/types";
import {
  sortProductTreeApi,
  discountsProductCreateApi,
  discountsProductPageApi,
  discountsProductRemoveApi, discountsProductModifyStatusApi
} from "@/apis/mall/discountsProduct";

@Component({})
export default class discountsProduct extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IDiscountsProduct[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IDiscountsProductParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IDiscountsProduct> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        label: '',
        type: 'selection',
        width: 50,
        align: 'center',
        selectable: (row: any, index: number) => {
          return row.isShow == 2
        }
      },
      {
        label: "商品编号",
        prop: "productId",
        align: "center",
        width: 180,
      },
      {
        label: "商品",
        prop: "productName",
        placeholder: '输入编号/名称模糊搜索',
        align: "center",
        hide: true,
        search: true
      },
      {
        label: "商品名称",
        prop: "productName",
        align: "center",
        overHidden: true
      },
      {
        label: "商品主图",
        prop: "productPic",
        align: "center",
        type: 'image',
        imgSuffix: ';'
      },
      {
        label: "排序",
        prop: "sort",
        align: "center",
      },
      {
        label: "首页展示",
        prop: "isShow",
        align: "center",
        type: 'select',
        search: true,
        slot: true,
        dicData: [
          {
            label: '展示',
            value: 1
          },
          {
            label: '不展示',
            value: 2
          }
        ]
      },
      {
        label: '商品分类',
        prop: 'sortId',
        search: true,
        hide: true,
        searchSlot: true
      },
      {
        label: '商品分类',
        prop: 'sortName',
        align: "center"
      },
      {
        label: '零售价（元）',
        prop: 'retailPrice',
        align: "center"
      },
      {
        label: '状态',
        prop: 'status',
        search: true,
        align: "center",
        type: "select",
        dicData: [
          {
            label: '上架',
            value: 1
          },
          {
            label: '下架',
            value: 2
          }
        ]
      },
    ]
  }

  sortList: IProductSort[] = []

  selectRows: IDiscountsProduct[] = []

  addDialog = false

  filterText = ''

  options: any = []

  @Watch('filterText')
  filterTextChange (newVal: string) {
    if (this.$refs.tree) {
      // @ts-ignore
      this.$refs.tree.filter(newVal);
    }
  }

  get ids() {
    return this.selectRows.map(item => item.id)
  }

  switchStatus (id: string, isShow: number) {
    discountsProductModifyStatusApi(id).then(e => {
      this.$message.success('操作成功!')
      this.getList()
    })
  }

  getSortProduct () {
    return new Promise(resolve => {
      sortProductTreeApi({}).then(e => {
        this.options = e;
        resolve(e)
      })
    })
  }

  async openAdd () {
    await this.getSortProduct()
    this.addDialog = true
  }

  addEnter (done: Function, loading: Function) {
    // @ts-ignore
    const arr: any = this.$refs.tree.getCheckedNodes()
    if (!arr || !arr.length) {
      this.$message.error('请选择数据')
      loading()
      return
    }
    const productArr: string[] = arr.filter((item: any) => item.productId).map((item: any) => item.productId)
    if (!productArr || !productArr.length) {
      this.$message.error('请选择商品数据')
      loading()
      return
    }
    discountsProductCreateApi({ids: productArr}).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  addClose () {
    this.filterText = ''
  }

  batchRemove (arr: string[]) {
    if (!arr || !arr.length) {
      return this.$message.error('请选择数据')
    }
    this.$confirm('是否确认移除？', '提示', {
      type: 'warning',
    }).then(() => {
      discountsProductRemoveApi(arr).then(e => {
        if (e) {
          this.$message.success('操作成功!')
          this.getList()
        }
      })
    }).catch(() => {
    })
  }

  getSortList (status?: number) {
    return new Promise(resolve => {
      sortPageApi({status}).then(e => {
        this.sortList = e;
        resolve(e)
      })
    })
  }

  filterNode (value: string, data: any) {
    if (!value) return true;
    return data.name.indexOf(value) !== -1;
  }

  getList () {
    this.tableLoading = true
    discountsProductPageApi(this.queryParam).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  created () {
    this.getSortList()
    this.getList()
  }
}
