import { render, staticRenderFns } from "./record.vue?vue&type=template&id=877294a0&scoped=true"
import script from "./record.vue?vue&type=script&lang=ts"
export * from "./record.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "877294a0",
  null
  
)

export default component.exports