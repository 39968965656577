/**
 * @name: 商城管理-超值特惠接口文件
 * @author: lili
 * @date: 2024-09-23 11:37
 * @description： 商城管理-超值特惠接口文件
 * @update: 2024-09-23 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IDiscountsProduct, IDiscountsProductParams } from "./types";

export const sortProductTreeApi = (params: {productName?: string}) => get('/admin/discountsProduct/create/productTree', params)

export const discountsProductCreateApi = (data: {ids: string[]}) => postJ('/admin/discountsProduct/create', data)

export const discountsProductPageApi = (params: IDiscountsProductParams) => get<IPageRes<IDiscountsProduct[]>>('/admin/discountsProduct/query', params)

export const discountsProductModifyStatusApi = (id: string) => postJ('/admin/discountsProduct/modifyStatus', {id})

export const discountsProductRemoveApi = (ids: string[]) => postJ('/admin/discountsProduct/remove', {ids})
