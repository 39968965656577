/**
 * @name: 商城管理-商品轮播图管理接口文件
 * @author: lili
 * @date: 2024-09-23 14:37
 * @description： 商城管理-商品轮播图管理接口文件
 * @update: 2024-09-23 14:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IBanner, IBannerParams } from "./types";

export const bannerPageApi = (params: IBannerParams) => get<IPageRes<IBanner[]>>("/admin/banner/product/query", params)

export const bannerDetailApi = (id: string) => get<IBanner>("/admin/banner/product/detail/" + id)

export const bannerCreateApi = (data: Partial<IBanner>) => postJ("/admin/banner/product/create", data)

export const bannerModifyApi = (data: Partial<IBanner>) => postJ("/admin/banner/product/modify", data)
