import {IConfig} from "@/config/types";

const config: IConfig = {
  version: "v1.4.4",
  project: "dzqf",
  httpPrefix: "/api",
  timeOut: 60 * 60 * 1000,
  platformName: "乾峰数商管理平台",
  company: "深圳市榆晟科技有限责任公司",
  uploadUrl: "/party/oss/ordinaryUpload",
  fragmentationUrl: "/party/oss/burstUpload",
  downloadUrl: "",
  videoDownloadUrl:'',
  rootPhones: [
    '15586782767'
  ]
}

export default config
