
/**
 * @name: 商品管理-货架管理
 * @author:lili
 * @date: 2024-05-14 14:37
 * @description： 商品管理-货架管理
 * @update: 2024-05-14 14:37
 */
import {Component, Vue} from "vue-property-decorator"
import config from "@/config";
import {ICrudOption} from "@/types/m-ui-crud";
import {sortPageApi} from "@/apis/product/sort";
import {IProduct, IProductSkuDto} from "@/apis/product/shelves/types";
import {IProductSort} from "@/apis/product/sort/types";
import {
  shelvesProductContributionApi,
  shelvesProductCreateApi,
  shelvesProductDetailApi,
  shelvesProductModifyApi
} from "@/apis/product/shelves";
import {Message} from "element-ui";
import {deepCopy} from "@/utils/common";
import Big from "big.js";

@Component({})
export default class productShelvesAddOrEdit extends Vue {

  // 查询参数
  queryParam: any = {
    id: 0,
  }
  pageJson:string='';
  radio: number = 3;
  isLoadingSubmit:boolean=false;
  // 表单参数
  modelForm: Partial<IProduct> = {
    isParcel:1,
    productType:1,
    settlementNode:1,
  }
  sortTreeList: IProductSort[] = []
  inputValue: string = ''
  productTags: any = []
  inputVisible: boolean = false
  InputRef: any = null

  // crud配置信息
  column = [
    {
      label: "",
      prop: "title1",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "商品名称",
      prop: "productName",
      align: 'center',
      maxlength: 100,
      overflow: 'hidden',
      rules: [
        {required: true, message: '请输入商品名称', trigger: 'blur'},
      ],
      span: 12,
    },
    {
      label: "商品分类",
      prop: "sortId",
      slot: true,
      align: 'center',
      rules: [
        {required: true, message: '请选择分类', trigger: 'blur'},
      ],
      span: 12,
    },
    {
      label: "成本价(元)",
      prop: "costPrice",
      align: 'center',
      maxlength: 10,
      rules: [
        {
          required: true,
          message: '请输入成本价',
          trigger: 'blur'
        },
        {
          validator: this.costPriceValidator,
          trigger: 'blur'
        }
      ],
      width: 150,
      span: 12,
    },
    {
      label: "零售价(元)",
      prop: "retailPrice",
      align: 'center',
      maxlength: 10,
      rules: [
        {
          required: true,
          message: '请输入零售价',
          trigger: 'blur'
        },
        {
          validator: this.retailPriceValidator,
          trigger: 'blur'
        }
      ],
      width: 150,
      span: 12,
    },
    {
      label: "标签",
      prop: "label",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "商品主图",
      prop: "productPic",
      type: 'image',
      align: 'center',
      slot: true,
      imgPrefix: config.downloadUrl,
      rules: [
        {required: true, message: '请上传商品图片', trigger: 'blur'},
      ],
      span: 24,
    },
    {
      label: "商品详情图",
      prop: "productDtlPic",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "",
      prop: "title2",
      align: 'center',
      slot: true,
      span: 24,
    },

    {
      label: "商品规格",
      prop: "productSku",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "规格属性",
      prop: "productSkuDtoList",
      align: 'center',
      slot: true,
      rules: [
        {required: true, message: '请设置规格', trigger: 'blur'},
        {validator: this.productSkuValidator,
          trigger: 'blur'
        }
      ],
      span: 24,
    },
    {
      label: "",
      prop: "title3",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "贡献值结算时间节点",
      prop: "settlementNode",
      align: 'center',//结算节点: 1=付款后 2=收货后
      rules: [
        {required: true, message: '请选择贡献值结算时间节点', trigger: 'blur'},
      ],
      slot: true,
      span: 24,
    },
    {
      label: "设置限购数量",
      prop: "quotaQty",
      align: 'center',
      maxlength: 10,
      rules: [
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value&&value!=''&&!/^[1-9]\d{0,5}$/.test(value)) {
              callback(new Error('请输入有效的限购数量1-999999'))
            }
            return callback()
          },
          trigger: 'blur'
        }
      ],
      width: 150,
      span: 12,
    },
    {
      label: "设置销售时间",
      type: 'daterange',
      prop: "saleTime",
      align: "center",
      slot: true,
      span: 16
    },
    {
      label: "设置banner宣传图",
      prop: "bannerPic",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "",
      prop: "title4",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "物流运输",
      prop: "isParcel",
      align: 'center',
      slot: true,
      rules: [
        {required: true, message: '请选择物流运输', trigger: 'blur'},
      ],
      span: 24,
    },
    {
      label: "物流运费(元)",
      prop: "postagePrice",
      align: 'center',
      slot: true,
      rules: [
        {
          required: true,
          validator: this.postagePriceValidator,
          trigger: 'blur'
        }
      ],
      span: 12,
    },

  ]

  column2 = [
    {
      label: "",
      prop: "title1",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "商品名称",
      prop: "productName",
      align: 'center',
      maxlength: 100,
      overflow: 'hidden',
      rules: [
        {required: true, message: '请输入商品名称', trigger: 'blur'},
      ],
      span: 12,
    },
    {
      label: "渠道来源",
      prop: "supplyType",
      align: "center",
      width: 150,
      type: "select",
      span: 12,
      disabled: true,
      search: true,
        dicData: [
          {
            label: "京东",
            value: 1
          },
          {
            label: "星链",
            value: 2
          },
          {
            label: "平台测试",
            value: 3
          },
          {
            label: "云商特卖",
            value: 4
          },
          {
            label: "厂商特卖",
            value: 5
          },
          {
            label: "天猫",
            value: 6
          },
          {
            label: "微唯宝特卖",
            value: 7
          },
          {
            label: "云链专供",
            value: 8
          },
          {
            label: "三方仓储",
            value: 9
          },
          {
            label: "华南一仓",
            value: 10
          },
          {
            label: "华南二仓",
            value: 11
          },
          {
            label: "华南二仓",
            value: 12
          },
          {
            label: "3C家电",
            value: 13
          },
          {
            label: "新疆专场",
            value: 14
          },
          {
            label: "西藏专场",
            value: 15
          },
          {
            label: "内蒙专场",
            value: 16
          },
          {
            label: "华东一仓",
            value: 17
          },
          {
            label: "企业专属",
            value: 18
          },
          {
            label: "云采渠道",
            value: 19
          },
          {
            label: "华东二仓",
            value: 20
          },
          {
            label: "华东三仓",
            value: 21
          },
          {
            label: "自营",
            value: 88
          }
        ]
    },

    {
      label: "商品分类",
      prop: "sortId",
      slot: true,
      align: 'center',
      rules: [
        {required: true, message: '请选择分类', trigger: 'blur'},
      ],
      span: 12,
    },
    {
      label: "成本价(元)",
      prop: "costPrice",
      align: 'center',
      maxlength: 10,
      disabled: true,
      width: 150,
      span: 12,
    },
    {
      label: "零售价(元)",
      prop: "retailPrice",
      align: 'center',
      maxlength: 10,
      rules: [
        {
          required: true,
          message: '请输入零售价',
          trigger: 'blur'
        },
        {
          validator: this.retailPriceValidator,
          trigger: 'blur'
        }
      ],
      width: 150,
      span: 12,
    },
    {
      label: "标签",
      prop: "label",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "商品主图",
      prop: "productPic",
      type: 'image',
      align: 'center',
      slot: true,
      imgPrefix: config.downloadUrl,
      rules: [
        {required: true, message: '请上传商品图片', trigger: 'blur'},
      ],
      span: 24,
    },
    {
      label: "商品详情图",
      prop: "productDtlPic",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "",
      prop: "title2",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "规格属性",
      prop: "productSkuDtoList",
      align: 'center',
      slot: true,
      span: 24,
    },
    {
      label: "",
      prop: "title3",
      align: 'center',
      slot: true,
      span: 24,
    },


    {
      label: "设置销售时间",
      type: 'daterange',
      prop: "saleTime",
      align: "center",
      slot: true,
      span: 16
    },
    {
      label: "设置banner宣传图",
      prop: "bannerPic",
      align: 'center',
      slot: true,
      span: 24,
    },

  ]
  skuForm: Partial<IProductSkuDto> = {
    totalContribute: 0
  }
  //*全网贡献值分成：
  typeTableData: any = [
    {
      name: '消费者',
      value: '',
      inputValue: '',
    },
    {
      name: 'VIP会员（VIP1—VIP7）',
      value: '',
      inputValue: '',
    },
    {
      name: '分享者',
      value: '',
      inputValue: '',
    },
    {
      name: '商家',
      value: '',
      inputValue: '',
    },
    {
      name: '领袖合伙人',
      value: '',
      inputValue: '',
    },
    {
      name: '精英合伙人',
      value: '',
      inputValue: '',
    }
  ]
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0

  //设置规格key,value
  specsList: any[] = [
    {
      name: '',
      inputVisible: false,
      setSkuTags: []
    }
  ];
  //设置规格
  setSkuDialog: boolean = false;
  //设置规格弹出窗列表
  setSkuList: any = []
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    menu: true,
    editBtn: true,
    delBtn: true,
    editTitle: "编辑规格",
    labelWidth: "130px",
    column: [
      {
        label: "序号",
        prop: "number",
        type:'number',
        align: 'center',
        slot:true,
        editHide:true
      },
      {
        label: "规格属性",
        prop: "name",
        align: 'center',
        width:200,
        span: 12,
        editSlot:true,
        rules: [
          {required: true, message: '规格属性有误', trigger: 'blur'},
        ],
      },
      {
        label: "可售库存",
        prop: "stock",
        align: 'center',
        span: 12,
        rules: [
          {required: false, trigger: 'blur',validator: (_rule: any, _value: any, _callback: any)=>{
            if(_value&&_value!=''&&!/^\d{0,5}$/.test(_value)){
              return _callback(new Error('可售库存有误，0-999999！'))
            }
            return _callback()
          }
          },
        ],
      },
      {
        label: "成本价(元)",
        prop: "costPrice",
        align: 'center',
        maxlength: 10,
        editSlot: true,
        rules: [
          {
            required: true,
            message: '请输入成本价',
            trigger: 'blur',
          },
          {
            validator: (_rule: any, _value: any, _callback: any) => this.costPriceValidator(_rule, _value, _callback, 'sku'),
            trigger: 'blur'
          },
        ],
        width: 150,
        span: 12,
      },
      {
        label: "零售价(元)",
        prop: "retailPrice",
        align: 'center',
        maxlength: 10,
        editSlot: true,
        rules: [
          {
            required: true,
            message: '请输入零售价',
            trigger: 'blur',
          },
          {
            validator: (_rule: any, _value: any, _callback: any) => this.retailPriceValidator(_rule, _value, _callback, 'sku'),
            trigger: 'blur'
          },
        ],
        width: 150,
        span: 12,
      },

      {
        label: "规格轮播图",
        prop: "skuPic",
        type: 'image',
        align: 'center',
        editSlot: true,
        width: 200,
        imgPrefix: config.downloadUrl,
        rules: [
          {required: true, message: '请上传规格轮播图', trigger: 'blur'},
        ],
        span: 24,
      },
      {
        label: "全网贡献值分成",
        prop: "type",
        align: 'center',
        editSlot: true,
        dicData: [
          {
            label: "自定义",
            value: 2
          },
          {
            label: "系统默认",
            value: 1
          },
        ],
        rules: [
          {required: true, validator: this.validatorType},
        ],
        hide: true
      },

    ]
  }

  /**
   * 规格属性
   * @param rule
   * @param value
   * @param callback
   */
  productSkuValidator(rule: any, value: any, callback: any) {
    let bol = false;
    this.tableData.forEach((item: any) => {
      if (item.skuPic == '' || item.skuPic == null || item.costPrice == '' || item.costPrice == null || item.retailPrice == '' || item.retailPrice == null) {
        bol = true;
      }
    })
    if(this.tableData&&this.tableData.length <= 0){
      return callback(new Error('请设置规格！'))
    }
    if (bol) {
      return callback(new Error('请补充规格属性详细信息！'))
    }
    return callback()
  }

  /**skuForm
   * 全网贡献值分配
   * @param rule
   * @param value
   * @param callback
   */
  validatorType(rule: any, value: any, callback: any) {
    if(this.skuForm.types==1){
      return callback()
    }
    let total = this.computeInputValue();
    let contribute = parseFloat((this.skuForm.totalContribute || 0).toString())
    console.log(total, contribute, 'validatorType')

    if (total <= 0 || total > 0 && (total > contribute || total < contribute)) {
      return callback(new Error('全网贡献值分配有误，请检查！'))
    }
    if(this.skuForm.types==2){
      let bol=false;
      this.typeTableData.forEach((e: any, i: number) => {
        let num = parseFloat(e['inputValue'] || 0);
        if(num<=0){
          bol=true;
        }
      })
      if(bol){
        return callback(new Error('全网贡献值不可以为空！'))
      }
    }
    return callback()
  }

  /**
   * 物流运费
   * @param rule
   * @param value
   * @param callback
   */
  postagePriceValidator(rule: any, value: any, callback: any) {
    if (this.modelForm.isParcel == 2 && (!/^\d+(\.\d{1,2})?$/.test(value) || parseFloat(value || '0') <= 0 || parseFloat(value) > 999999.99)) {
      callback(new Error('请输入有效的物流运费0.01-999999.99'))
    }
    return callback()
  }

  /**
   *
   * 成本价不能大于等于零售价；
   * @param rule
   * @param value
   * @param callback
   * @type type=sku 规格
   */
  costPriceValidator(rule: any, value: any, callback: any, type?: string) {
    if (!value || value == '') {
      callback(new Error('请输入成本价'))
    }
    if (!/^(?:0|[1-9]\d{0,5})(?:\.\d{1,2})?$/.test(value) || parseFloat(value || '0') <= 0 || parseFloat(value) > 999999.99) {
      callback(new Error('请输入有效的成本价0.01-999999.99'))
    }
    let price = type == 'sku' ? (this.skuForm.retailPrice) : (this.modelForm.retailPrice);
    if (price && parseFloat(value) >= price) {
      callback(new Error('成本价不能大于等于零售价'))
    }
    return callback()
  }

  /**
   * 规格
   * 零售价不能小于等于成本价；
   * @param rule
   * @param value
   * @param callback
   * @type type=sku 规格
   */
  retailPriceValidator(rule: any, value: any, callback: any, type?: string) {
    if (!value || value == '') {
      callback(new Error('请输入零售价'))
    }
    if (!/^(?:0|[1-9]\d{0,5})(?:\.\d{1,2})?$/.test(value) || parseFloat(value || '0') <= 0 || parseFloat(value) > 999999.99) {
      callback(new Error('请输入有效的零售价0.01-999999.99'))
    }
    let price = (type == 'sku' ? (this.skuForm.costPrice || 0) : (this.modelForm.costPrice || 0))
    if (price && parseFloat(value) <= price) {
      callback(new Error('零售价不能小于等于成本价'))
    }
    return callback()
  }

  /**
   * 标签删除
   * @param tag
   * @param i
   */
  handleClose(tag: string, i: number) {
    if (this.setSkuDialog) {
      this.setSkuList[i].setSkuTags.splice(this.setSkuList[i].setSkuTags.indexOf(tag), 1);
    } else
      this.productTags.splice(this.productTags.indexOf(tag), 1)
  }

  /**
   * 标签输入框
   */
  showInput(i: number) {
    if(this.setSkuDialog)
    {
      this.setSkuList=this.setSkuList.map((e:any)=>{e.inputVisible=false;return e})
      this.setSkuList[i].inputVisible=true;
    }
      else
    this.inputVisible = true
  }

  /**
   * 标签输入确认
   * @param i
   */
  handleInputConfirm(i: number) {
    if (this.inputValue&&this.setSkuDialog) {
      let findex=this.setSkuList[i].setSkuTags.findIndex((e:any)=>e==this.inputValue)
      if(findex!=-1){
        Message.error("同规格属性值不可重复!")
        return
      }else if(this.setSkuList[i].setSkuTags.length>=100) {
        Message.error("最多可添加100个属性值!")
        return
      }

      this.setSkuList[i].setSkuTags.push(this.inputValue);
      this.setSkuList[i].inputVisible=false;
    }
    if (i == -1 && this.inputValue&&!this.setSkuDialog) {
      let findex=this.productTags.findIndex((e:any)=>e==this.inputValue)
      if(findex!=-1){
        Message.error("同规格属性值不可重复!")
        return
      }else if(this.productTags.length>=100) {
        Message.error("最多可添加100个属性值!")
        return
      }
      this.productTags.push(this.inputValue)
      this.inputVisible = false
    }
    this.inputValue = ''
  }



  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    const fileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    const fileSize = file.size / 1024 < 500;

    if (!fileType) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!fileSize) {
      this.$message.error('上传图片大小不能超过 500KB!');
    }
    return fileType && fileSize;
  }

  /**
   * 分类清除
   */
  blurDept() {
    const _cascader = this.$refs.myCascader
    if (_cascader) {
      // @ts-ignore
      _cascader.$refs.panel.checkedValue = []
      // @ts-ignore
      _cascader.$refs.panel.activePath = []
      // @ts-ignore
      _cascader.$refs.panel.syncActivePath()
      // @ts-ignore
      _cascader.$refs.panel.clearCheckedNodes()
    }
  }

  /**
   * 生成sku
   */
  handleSKU() {
    //列表
    this.tableData = [];
    //选择的sku 二维数组
    let SKUList = new Array();
    let bol = false;
    this.specsList.forEach((item, index) => {
      let specs: any = []
      if (!item.name || item.name == '')
        bol = true
      item.setSkuTags.forEach((sku: any, i: number) => {
        let o: any = {number: i, id: '', specsValueId: '', skuName: item.name};
        o.name = item.name + ';' + sku;
        o.value = sku;
        specs.push(o);
      })
      console.log(specs,'specs',specs.length)
      if (specs.length > 0)
        SKUList.push(specs);
      else
        bol = true
    })
    if (bol) {
      return bol
    } else if (SKUList) {
      //多维数组组合
      let newArr = this.doExchange(SKUList) as any;
      //组装sku key value List
      let spList = new Array();
      //组合完成 列表数据
      let productSkuList = [];
      //组合需要数据
      for (let newArrElement of newArr) {
        //列表对象
        let obj = {
          spData: null,
          skuPic: '',
          costPrice: '',
          retailPrice: '',
          stock: '',
          productId: this.modelForm.id,
          name: '',
          type: 1,
          skuCode: ''
        } as any;
        if (!newArrElement.length) {
          spList.push({key: newArrElement.skuName, value: newArrElement.value});
          obj.name = [newArrElement.value]
        } else {
          for (let i = 0; i < newArrElement.length; i++) {
            spList.push({key: newArrElement[i].skuName, value: newArrElement[i].value});
          }
          obj.name = newArrElement.map((e: any) => e.value);
        }
        obj.spData = JSON.stringify(spList);
        obj.name= obj.name.join(';')
        productSkuList.push(obj)
        spList = [];
      }
      this.tableData = productSkuList;
      this.modelForm.productSkuDtoList = this.tableData;
    }

  }

  /**
   * 多维数组组合
   */
  // @ts-ignore
  doExchange(arr: any) {
    let len = arr.length;
    // 当数组大于等于2个的时候
    if (len >= 2) {
      // 第一个数组的长度
      let len1 = arr[0].length;
      // 第二个数组的长度
      let len2 = arr[1].length;
      // 2个数组产生的组合数
      let lenBoth = len1 * len2;
      //  申明一个新数组
      let items = new Array(lenBoth);
      // 申明新数组的索引
      let index = 0;
      for (let i = 0; i < len1; i++) {
        for (let j = 0; j < len2; j++) {
          if (arr[0][i] instanceof Array) {
            items[index] = arr[0][i].concat(arr[1][j]);
          } else {
            items[index] = [arr[0][i]].concat(arr[1][j]);
          }
          index++;
        }
      }
      let newArr = new Array(len - 1);
      for (let i = 2; i < arr.length; i++) {
        newArr[i - 1] = arr[i];
      }
      newArr[0] = items;
      return this.doExchange(newArr);
    } else {
      return arr[0];
    }
  }

  /**
   * 打开
   */
  openSkuDialog() {
    this.setSkuDialog = true;
    this.setSkuList = deepCopy(this.specsList);
  }
  /**
   * 关闭
   */
  setSkuClose() {
    this.setSkuDialog = false;
    this.setSkuList = deepCopy(this.specsList);
  }
  skuNameBlur(i:number){
    var pattern = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/;
    if(!pattern.test(this.setSkuList[i].name)){
      this.setSkuList[i].name='';
      Message.error('规格名称不能为空并只能输入中文、字母、数字！');
      return true;
    }
    return false;
  }
  /**
   *确认设置规格
   */
  setSkuEnter(done: Function, loading: Function) {
    this.specsList = deepCopy(this.setSkuList);
   try {
     let bol = this.handleSKU();
     if (bol) {
       Message.error('规格名称或规格属性值不能为空！');
     } else
       done()
     loading()
   }catch (e){
     loading()

   }
  }

  /**
   * 新增规格
   * 限5个
   */
  handleAddSetSku(){
    if(this.setSkuList.length>=5){
      return;
    }
    this.setSkuList.push({
      name:'',
      inputVisible:false,
      setSkuTags:[]
    })
  }

  /**
   * 删除规格
   */
  handleSetSkuDel(index:number){
    this.setSkuList.splice(index, 1)
  }

  /**
   * 打开编辑规格
   * @param row
   */
  async openEdit(row: any, index: any) {
    this.skuForm = row;
    this.skuForm.index = index || 0;
    this.typeTableData = this.typeTableData.map((item: any) => {
      item.value = '';
      item.inputValue = '';
      return item
    })
    if (this.skuForm.types == 2 && this.skuForm.costPrice && (parseFloat(this.skuForm.retailPrice as any) > this.skuForm.costPrice)) {
      await this.getSkuContribution();
    }
    if (this.skuForm.productSkuContributeVo) {
      let str = this.skuForm.types == 1 ? 'value' : 'inputValue';
      await this.setTypeTable(this.skuForm.productSkuContributeVo, str);
    } else {
      this.skuForm.totalContribute = 0.000000;
    }
    this.$nextTick(async () => {
      if (!this.skuForm.types)
        this.skuForm.types = 1;
      // @ts-ignore
      await this.$refs.crudRef.rowEdit(this.skuForm, index || 0)
    })
  }

  /**skuForm
   * 规格价格blur计算 全网贡献值分成
   */
  inputBlurPrice(type: string) {
    if (this.skuForm.costPrice&&this.skuForm.costPrice>0 && this.skuForm.retailPrice&&this.skuForm.retailPrice>0) {
      if ((parseFloat(this.skuForm.retailPrice as any) <= this.skuForm.costPrice)) {
        if (type == 'costPrice') {
          // @ts-ignore
          this.$refs.crudRef.$refs.MFormRef.$refs.FormRef.validateField('costPrice');
        } else {
          // @ts-ignore
          this.$refs.crudRef.$refs.MFormRef.$refs.FormRef.validateField('retailPrice');
        }
        return
      } else {
        this.getSkuContribution();
        this.typeTableData = this.typeTableData.map((item: any) => {
          item.inputValue = '';
          return item
        })

      }

    }
  }

  /**
   * *全网贡献值分成
   * @param res
   */
  setTypeTable(res: any,str:string) {
    this.typeTableData[0][str] = res.consumerContribute;
    this.typeTableData[1][str] = res.memberContribute;
    this.typeTableData[2][str]  = res.shareContribute;
    this.typeTableData[3][str]  = res.storeContribute;
    this.typeTableData[4][str] = res.cityContribute;
    this.typeTableData[5][str]  = res.communityContribute;
    this.$set(this, 'typeTableData', this.typeTableData);
    this.$set(this.skuForm, 'totalContribute', this.skuForm.totalContribute);
  }

  async getSkuContribution() {
    let form = JSON.parse(JSON.stringify(this.skuForm)) as any
    delete form.spData
    await shelvesProductContributionApi(form).then(async res => {
      if (res && res.totalContribute) {
        this.skuForm.totalContribute = res.totalContribute;
        this.$nextTick(() => {
          this.$set(this, 'skuForm', this.skuForm);
        })
      }
      if (res && res.productSkuContributeVo) {
        await this.setTypeTable(res.productSkuContributeVo, 'value');
      }

    })
  }
  changeTypesGroup(){
    if (this.skuForm.types == 1 && (this.skuForm.costPrice && this.skuForm.costPrice > 0 && this.skuForm.retailPrice && this.skuForm.retailPrice > 0))
      this.getSkuContribution();
  }
  /**
   * 规格全网贡献值分成-输入框 总计算
   */
  computeInputValue() {
    let total = 0;
    let str = this.skuForm.types == 1 ? 'value' : 'inputValue';
    this.typeTableData.forEach((e: any, i: number) => {
      total = parseFloat(new Big(total).plus(parseFloat(e[str] || 0)).toFixed(6, 0))
    })
    return (total || 0)
  }

  /**
   * 全网贡献值分成可分配 剩下数
   */
  computeTotalContribute(){
    let v=this.computeInputValue();
    let total=parseFloat(new Big((this.skuForm.totalContribute||0)).minus(v).toFixed(6,0))
    return (total || 0.000000);
  }
  /**
   * 规格全网贡献值分成-输入框
   * @param row
   */
  typeTableInput(row: any, $index: number,v:string){
    if(this.computeInputValue()>(this.skuForm.totalContribute||0)){
      Message.error('输入的总和不能大于可分配会员贡献值总数');
      row.inputValue='';
      this.typeTableData[$index].inputValue='';
      return false;
    }
    return true;
  }
  /**
   * 删除规格
   * @param row
   */
  async openDel(row: any, $index: number) {
    this.skuForm = row;
    this.skuForm.index = $index;
    // @ts-ignore
    this.$refs.crudRef.rowDel(row, $index);
  }
  /**
   * 修改提交
   */
  submitEdit(form: any, done: any, loading: any) {
    try {
      // @ts-ignore
      this.$refs.crudRef.$refs.MFormRef.$refs.FormRef.validate(async valid => {
        if (valid) {
          let str = this.skuForm.types == 1 ? 'value' : 'inputValue';
          let contribute = {} as any;
          contribute.consumerContribute = this.typeTableData[0][str];
          contribute.memberContribute = this.typeTableData[1][str];
          contribute.shareContribute = this.typeTableData[2][str];
          contribute.storeContribute = this.typeTableData[3][str];
          contribute.cityContribute = this.typeTableData[4][str];
          contribute.communityContribute = this.typeTableData[5][str];
          let obj = Object.assign(form, contribute);
          if(this.skuForm.productSkuContributeVo)
            obj.productSkuContributeVo = Object.assign(this.skuForm.productSkuContributeVo, contribute);
          else
            obj.productSkuContributeVo=Object.assign({},contribute);
          this.$set(this.tableData, this.skuForm.index || 0, obj)
          done();
          loading();
        } else
          loading();
      })
    } catch (e) {
      console.log(e)
      loading();
    }
  }

  /**
   * 删除提交
   */
  submitRemove(form: any) {
   this.tableData.splice(form.index, 1);
    Message.success("操作成功!");
  }

  /**
   *
   * 表单提交
   * @param form
   * @param event
   */
  onSubmit(form: any, event: any) {
    this.isLoadingSubmit=true;
    let dataForm: any =deepCopy(this.modelForm);
    dataForm.label = this.productTags.join(';');
    dataForm.productSkuDtoList = this.tableData;
    if (this.modelForm.saleTime && this.modelForm.saleTime.length > 1) {
      dataForm.saleStartTime = this.modelForm.saleTime[0]
      dataForm.saleEndTime = this.modelForm.saleTime[1]
    } else {
      dataForm.saleStartTime = ''
      dataForm.saleEndTime = ''
    }
    // @ts-ignore
    this.$refs.MFormRef.$refs.FormRef.validate(async valid => {
      if (valid) {
        try {
          if (this.modelForm.id) {
            shelvesProductModifyApi(dataForm).then((e: any) => {
              if (e) {
                Message.success('修改成功！')
                this.handleBackPage()
              }
            }).finally(()=>{
              this.isLoadingSubmit = false;
            })
          } else {
            shelvesProductCreateApi(dataForm).then((e: any) => {
              if (e) {
                Message.success('新增成功！')
                this.handleBackPage()
              }
            }).finally(()=>{
              this.isLoadingSubmit = false;
            })
          }
        } catch (err) {
          console.log(err,'err---')
          this.isLoadingSubmit = false;
        }
      }else
       this.isLoadingSubmit=false;
    })
  }

  /**
   * 返回列表页面或者 取消表单
   */
  handleBackPage() {
    this.$store.dispatch('delView', {path: '/product/shelves', name: "productShelves"});
    this.$store.dispatch('delView', {path: '/product/add-edit-shelves', name: "addOrEditShelves"});
    this.$router.push({path: '/product/shelves',query:{}})
  }

  /**
   * 查询详情
   */
  getDetail() {
    if (!this.$route.query.id) {
      return
    }
    this.queryParam.id = this.$route.query.id;
    shelvesProductDetailApi(this.queryParam.id).then(res => {
     let data = res;
     if(!data.settlementNode&&data.settlementNode<1){
       data.settlementNode=1;
     }
      data.isParcel = 1;
      //是否有运费,有,不包邮f
      if (res.postagePrice && parseFloat(res.postagePrice) > 0) {
        data.isParcel = 2;
      }
      if (res.saleStartTime && res.saleEndTime) {
        data.saleTime = [res.saleStartTime, res.saleEndTime];
      }
      if (res.label) {
        this.productTags = res.label.split(';') as any;
      }
      if (data.productSkuVoList) {
        data.productSkuDtoList =data.productSkuVoList;
        //sku key value
        let SkuVoList: any = [];
        let SKuKeyList: any = [];
        for (let skuItem of data.productSkuVoList) {
          let obj = skuItem as any;
          let spData = JSON.parse(skuItem.spData);
          let n = spData.map((e: any) => e.value);
          obj.name = n.join(';')
          SKuKeyList = spData.map((e: any) => e.key);
          SkuVoList = [...SkuVoList, ...spData]
          if (!obj.types)
            obj.types = 1;
          if(obj.productSkuContributeVo)
          obj.productSkuContributeId=obj.productSkuContributeVo.id;
          this.tableData.push(obj)
        }
        this.setSkuList = [];
        this.initSetSpecs(SkuVoList, SKuKeyList)
      }
      this.$set(this,'modelForm',data);
    })
  }

  /**
   * 商品规格回显
   */
  initSetSpecs(arrData: any[], KeyList: any[]) {
    if(KeyList.length>0)
      this.specsList = [];
    //规格key列表
    KeyList.forEach(async (SpecsValue: any) => {
      //规格对象
      let obj: any = {};
      obj.inputVisible = false;
      obj.setSkuTags = [];
      for (let Item of arrData) {
        //sIndex查找 sku value 与规格属性 是否已存在
        let sIndex = obj.setSkuTags.findIndex((e: any) => e == Item.value);
        if (SpecsValue == Item.key && sIndex == -1) {
          obj.name = Item.key;
          obj.setSkuTags.push(Item.value);
        }
      }
      this.specsList.push(obj);
    })
  }

  changeRadioGroup(e:any){
    if(this.modelForm.isParcel==1){
      this.modelForm.postagePrice='0';
    }
    this.$nextTick(()=>{
      this.$set(this.modelForm,'isParcel',this.modelForm.isParcel);
    })
  }
  /**
   * 获取商品分类列表
   * @param status 显示状态
   */
  getSortList(status?: number) {
    return new Promise(resolve => {
      sortPageApi({status}).then(e => {
        this.sortTreeList = e;
        resolve(e)
      })
    })
  }


  created() {
    this.getSortList();
    this.getDetail()
  }
}
