
/**
 * @name: 商品管理-商品分类管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商品管理-商品分类管理
 * @update: 2024-05-13 11:37
 */
import {Component, Vue} from "vue-property-decorator"
import {checkSort} from '@/constants/validators'
import {
  sortDetailApi,
  sortFCreateApi,
  sortModifyApi,
  sortPageListApi,
  sortSCreateApi,
  sortTCreateApi
} from '@/apis/product/sort'
import {IProductSort, IProductSortParams} from '@/apis/product/sort/types'
import type {ICrudOption} from "@/types/m-ui-crud"

@Component({})
export default class productSort extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IProductSort[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IProductSortParams = {}
  // 表单参数
  modelForm: Partial<IProductSort> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    dialogWidth: '600px',
    column: [
      {
        label: "分类",
        prop: "sortName",
        placeholder: "分类ID/分类名称",
        search: true,
        addHide: true,
        editHide: true,
        hide: true
      },
      {
        label: "分类ID",
        prop: "id",
        align: "left",
        addHide: true,
        editHide: true
      },
      {
        label: "分类名称",
        prop: "sortName",
        overHidden: true,
        align: "center",
        maxlength: 10,
        rules: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          }
        ]
      },
      {
        label: "分类图片",
        prop: "sortIcon",
        align: "center",
        type: "image",
        rules: [
          {
            required: true,
            message: '请上传分类图片',
            trigger: 'blur'
          }
        ],
        addSlot: true,
        editSlot: true
      },
      {
        label: "排序",
        prop: "sort",
        align: "center",
        rules: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'blur'
          },
          {
            validator: checkSort,
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (parseInt(value) > 99) {
                return callback(new Error('排序最大99'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        search: true,
        addHide: true,
        editHide: true,
        slot: true,
        type: "select",
        dicData: [
          {
            label: "启用",
            value: 1
          },
          {
            label: "禁用",
            value: 2
          }
        ]
      }
    ]
  }

  beforeUpload (file: File) {
    const fileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    const fileSize = file.size / 1024 < 200;

    if (!fileType) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!fileSize) {
      this.$message.error('上传图片大小不能超过 200KB!');
    }
    return fileType && fileSize;
  }

  openAdd(row?: any) {
    let title = `新增${row ? row.level == 1 ? '二' : '三' : '一'}级分类`;
    // @ts-ignore
    this.$refs.crudRef.rowAdd({
      title: title
    })

    setTimeout(() => {
      this.$set(this.modelForm, 'pid', row.id || '')
      this.$set(this.modelForm, 'level', ((row.level || 1) + 1))
      if (row.level == 1) {
        // @ts-ignore
        this.$refs.crudRef.updateFormColumn('sortIcon', {
          hide: true,
        })
      }
    }, 0)

  }
  openEdit (row: IProductSort, index: number) {
    let title = `编辑${row.level == 1 ? '一' : row.level == 2 ? '二' : '三'}级分类`;
    sortDetailApi(row.id).then(e => {
      let obj: IProductSort = e;
      obj.level = row.level;
      // @ts-ignore
      this.$refs.crudRef.rowEdit(obj, index, {
        title: title
      })
      setTimeout(() => {
        if (row.level == 2) {
          // @ts-ignore
          this.$refs.crudRef.updateFormColumn('sortIcon', {
            hide: true,
          })
        }
      }, 0)
    })
  }

  switchStatus (id: string, status: number) {
    sortModifyApi({id, status}).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
      }
    })
  }

  rowSave (form: IProductSort, done: Function, loading: Function) {
    let func: Function;
    if (form.level == 3) {
      func = sortTCreateApi
    } else if (form.level == 2) {
      func = sortSCreateApi
    } else {
      func = sortFCreateApi
    }
    func(form).then((e: any) => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IProductSort, done: Function, loading: Function) {
    sortModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  getList () {
    this.tableLoading = true

    sortPageListApi(this.queryParam).then(e => {
      this.tableData = e
      this.tableLoading = false
    })
  }

  created () {
    this.getList()
  }
}
