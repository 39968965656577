
/**
 * @name: 商城管理-banner轮播图管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-banner轮播图管理
 * @update: 2024-05-13 11:37
 */
import {Component, Vue} from "vue-property-decorator"
import {productDropApi} from '@/apis/product/third-product'
import {bannerCreateApi, bannerDetailApi, bannerModifyApi, bannerPageApi} from '@/apis/mall/banner'
import {IBanner, IBannerParams} from '@/apis/mall/banner/types'
import {checkSort} from '@/constants/validators'
import type {ICrudOption} from "@/types/m-ui-crud"
import {IProduct} from "@/apis/product/third-product/types";
import {deepCopy} from "@/utils/common"

@Component({})
export default class mallBanner extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IBanner[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IBannerParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IBanner> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menuWidth: 100,
    column: [
      {
        label: '序号',
        type: 'index',
        width: 50,
        align: 'center',
        addHide: true,
        editHide: true
      },
      {
        label: '跳转商品',
        prop: 'productName',
        search: true,
        addHide: true,
        editHide: true,
        align: 'center',
        overHidden: true
      },
      {
        label: '图片',
        prop: 'pic',
        align: 'center',
        type: 'image',
        imgSuffix: ';',
        span: 24,
        width: 120,
        rules: [
          {
            required: true,
            message: '请上传图片',
            trigger: 'blur'
          }
        ],
        addSlot: true,
        editSlot: true
      },
      {
        label: '排序',
        prop: 'sort',
        align: 'center',
        width: 100,
        rules: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'blur'
          },
          {
            validator: checkSort,
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (parseInt(value) > 99) {
                return callback(new Error('排序最大值99'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      },
      {
        label: '跳转商品',
        prop: 'productId',
        hide: true,
        type: 'select',
        addSlot: true,
        editSlot: true
      },
      {
        label: '状态',
        prop: 'status',
        align: 'center',
        width: 100,
        slot: true,
        editHide: true,
        addHide: true,
        search: true,
        type: 'select',
        dicData: [
          {
            label: '启用',
            value: 1
          },
          {
            label: '禁用',
            value: 2
          }
        ]
      },
      {
        label: '展示时间',
        prop: 'showStartTime',
        align: 'center',
        width: 260,
        slot: true,
        addSlot: true,
        editSlot: true
      },
      {
        label: '创建时间',
        prop: 'addTime',
        width: 180,
        align: 'center',
        addHide: true,
        editHide: true
      }
    ]
  }

  productSearchLoading = false

  productList: IProduct[] = []

  remoteMethod(query: string) {
    this.productSearchLoading = true;
    productDropApi({status: 1, productName: query}).then(e => {
      if (e) {
        this.productSearchLoading = false
        this.productList = e
      }
    })
  }


  switchStatus (id: string, status: number) {
    bannerModifyApi({id, status}).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
      }
    })
  }

  beforeUpload (file: File) {
    const fileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    const fileSize = file.size / 1024 < 500;

    if (!fileType) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!fileSize) {
      this.$message.error('上传图片大小不能超过 500KB!');
    }
    return fileType && fileSize;
  }

  openAdd() {
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
    setTimeout(() => {
      this.$set(this.modelForm, 'showTime', [])
      this.productList = []
      this.productSearchLoading = false
    }, 0)
  }

  async openEdit(row: IBanner, index: number) {
    this.productList = []
    this.productSearchLoading = false
    await this.remoteMethod(row.productName)
    await bannerDetailApi(row.id).then(e => {
      const detail: IBanner = deepCopy(e)
      if (e.showStartTime && e.showEndTime) {
        detail.showTime = [e.showStartTime, e.showEndTime]
      } else {
        detail.showTime = []
      }
      // @ts-ignore
      this.$refs.crudRef.rowEdit(detail, index)
    })
  }

  rowSave (form: IBanner, done: Function, loading: Function) {
    const data: IBanner = deepCopy(form)
    if (data.showTime && data.showTime.length === 2) {
      data.showStartTime = data.showTime[0]
      data.showEndTime = data.showTime[1]
    } else {
      data.showStartTime = ''
      data.showEndTime = ''
    }
    delete data.showTime
    bannerCreateApi(data).then(e => {
      if(e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IBanner, done: Function, loading: Function) {
    const data: IBanner = deepCopy(form)
    if (data.showTime && data.showTime.length === 2) {
      data.showStartTime = data.showTime[0]
      data.showEndTime = data.showTime[1]
    } else {
      data.showStartTime = ''
      data.showEndTime = ''
    }
    delete data.showTime
    bannerModifyApi(data).then(e => {
      if(e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  getList () {
    this.tableLoading = true

    bannerPageApi(this.queryParam).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  created () {
    this.getList()
  }
}
