/**
 * @name: 商城管理-应季上新接口文件
 * @author: lili
 * @date: 2024-09-23 11:37
 * @description： 商城管理-应季上新接口文件
 * @update: 2024-09-23 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ISeasonalProduct, ISeasonalProductParams} from "./types";

export const sortProductTreeApi = (params: {productName?: string}) => get('/admin/seasonalProduct/create/productTree', params)

export const seasonalProductCreateApi = (data: {ids: string[]}) => postJ('/admin/seasonalProduct/create', data)

export const seasonalProductPageApi = (params: ISeasonalProductParams) => get<IPageRes<ISeasonalProduct[]>>('/admin/seasonalProduct/query', params)

export const seasonalProductModifyStatusApi = (id: string) => postJ('/admin/seasonalProduct/modifyStatus', {id})

export const seasonalProductRemoveApi = (ids: string[]) => postJ('/admin/seasonalProduct/remove', {ids})
